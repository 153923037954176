<template>
  <div class="column-menu dropdown-menu" :class="{ 'small-menu': isMobile }">
    <div class="menu-title">
      <NuxtLink :to="`${menuParentSlug}/`" class="link">{{ menuTitle }}</NuxtLink>
      <Icon
        class="accordion-arrow"
        :class="{ rotate: itemOpened }"
        name="arrow-dropdown"
        fill="var(--color-primary-black)"
        @click="itemOpened = !itemOpened"
      />
    </div>
    <ul v-if="itemOpened">
      <li v-for="menuItem in menuItems" :key="menuItem.id">
        <NuxtLink :to="`${menuParentSlug}/${menuItem.slug}/`" class="link">{{
          menuItem.title
        }}</NuxtLink>
      </li>
    </ul>
  </div>
</template>
<script lang="ts" setup>
import { MenuItemProps } from '@/lib/types/menuGuides';
const {
  menuTitle,
  isMobile = false,
  menuItems
} = defineProps<{
  menuTitle?: string;
  isMobile?: boolean;
  menuItems?: MenuItemProps[];
  menuParentSlug?: string;
}>();
const itemOpened = $ref(!isMobile);
</script>
<style lang="scss" scoped>
@use '$/breakpoints.scss';

.column-menu {
  display: flex;
  flex-direction: column;
  width: 200px;

  .menu-title {
    margin-bottom: 5px;

    .link {
      display: flex;
      gap: 10px;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .link {
    &:hover {
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }
}

.dropdown-menu {
  max-height: 2000px;

  .accordion-arrow {
    display: none;
  }

  ul {
    overflow: hidden;
    max-height: 1000px;

    li {
      .link {
        display: block;
        margin-left: 5px;
      }
    }
  }

  &.small-menu {
    .menu-title .link {
      font-size: 16px;
      font-weight: 500;
    }

    .accordion-arrow {
      display: flex;
    }

    .rotate {
      transform: rotate(180deg);
    }
  }
}
</style>
<template>
  <nav :class="{ 'small-menu': isMobile }">
    <div class="wrapper" :class="{ 'small-menu': isMobile }">
      <div class="nav-title">
        <NuxtLink :to="`/${basePath}/`" class="link">Tous les guides {{ guideName }}</NuxtLink>
      </div>
      <ul class="mega-menu">
        <li v-for="menuItem in menuItems" :key="menuItem.id">
          <div class="dropdown">
            <NuxtLink :to="`/${basePath}/${menuItem.slug}/`" class="mega-menu-title">
              {{ menuItem.title }}
            </NuxtLink>
            <Icon
              class="accordion-arrow"
              :class="{ opened: menuOpened === menuItem.id }"
              name="arrow-dropdown"
              fill="var(--color-primary-black)"
              @click="toggleMenu(menuItem.id)"
            />
          </div>
          <div class="sub-menu" :class="{ opened: menuOpened === menuItem.id }">
            <div class="sub-menu-title">
              <NuxtLink :to="`/${basePath}/${menuItem.slug}/`" class="link">
                {{ menuItem.title }}</NuxtLink
              >
            </div>
            <div class="content">
              <MenuColumn
                v-for="children in menuItem.children"
                :key="children.id"
                :menu-title="children.title"
                :menu-parent-slug="`/${basePath}/${menuItem.slug}/${children.slug}`"
                :menu-items="children.children"
                :is-mobile="isMobile"
              ></MenuColumn>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>
    
  <script lang="ts" setup>
import { MenuItemProps } from '@/lib/types/menuGuides';
const { isMobile = false } = defineProps<{
  isMobile?: boolean;
  guideName?: string;
}>();
let menuOpened = $ref<number | null>(null);
let contextFilters = {};
let level = 0;

const toggleMenu = (menuId: number) => {
  menuOpened = menuId === menuOpened ? null : menuId;
};
const route = useRoute();
const updateContextFilters = () => {
  if (route.params.continentid && route.params.countryid === undefined) {
    contextFilters = { mv_continent: { slug: route.params.continentid } };
    level = 4;
  } else if (route.params.countryid) {
    contextFilters = { country: { slug: route.params.countryid } };
    level = 5;
  } else if (route.params.inspirationsslug) {
    contextFilters = { mv_theme: { slug: route.params.inspirationsslug } };
    level = 5;
  }
};
watch(
  () => route.params,
  async () => {
    updateContextFilters();

    const [{ guidesHierarchy }] = await Promise.all([
      useFindGuidesHierarchy({
        filters: contextFilters
      })
    ]);
    return guidesHierarchy;
  },
  { immediate: true }
);

const [{ guidesHierarchy }] = await Promise.all([
  useFindGuidesHierarchy({
    filters: contextFilters
  })
]);

const basePath = [...route.path.split('/').slice(1, level - 1)].join('/');

const menuItems: MenuItemProps[] = [];
// If we have the guidesHierarchy, we can build the menu items
if (guidesHierarchy) {
  // Let's build the menu items for LVL5
  const guidesNV5 = guidesHierarchy.filter(
    guideMenuItem => guideMenuItem?.attributes?.mv_guide_level?.data?.attributes?.level === level
  );

  guidesNV5.forEach(guide => {
    if (guide.id) {
      if (!menuItems.some(item => item.id === guide.id)) {
        menuItems.push({
          id: guide.id,
          title: guide.attributes.title,
          slug: guide.attributes.slug,
          children: []
        });
      }
    }
  });

  // Let's build the menu items for LVL6 and add their children to their parent
  const guidesNV6 = guidesHierarchy.filter(
    guideMenuItem =>
      guideMenuItem?.attributes?.mv_guide_level?.data?.attributes?.level === level + 1
  );

  guidesNV6.forEach(guide => {
    const guideParentId = guide?.attributes?.mv_guide_parent?.data?.id;
    if (guideParentId) {
      const parentItem = menuItems.find(item => item.id === guideParentId);
      if (parentItem) {
        if (!parentItem.children?.some(child => child.id === guide.id)) {
          parentItem.children?.push({
            id: guide.id,
            title: guide.attributes.title,
            slug: guide.attributes.slug,
            children: []
          });
        }
      }
    }
  });
  // Let's build the menu items for LVL7 and add their children to their parent
  const guidesNV7 = guidesHierarchy.filter(
    guideMenuItem =>
      guideMenuItem?.attributes?.mv_guide_level?.data?.attributes?.level === level + 2
  );
  guidesNV7.forEach(guide => {
    const guideParentId = guide?.attributes?.mv_guide_parent?.data?.id;
    if (guideParentId) {
      menuItems.forEach(parentItem => {
        parentItem?.children?.forEach(childItem => {
          if (childItem.id === guideParentId) {
            if (!parentItem?.children?.some(child => child.id === guide.id)) {
              childItem?.children?.push({
                id: guide.id,
                title: guide.attributes.title,
                slug: guide.attributes.slug,
                children: []
              });
            }
          }
        });
      });
    }
  });
}
</script>
    
    <style lang="scss" scoped>
@use '$/shadows.scss';

nav {
  position: relative;
  z-index: 5;

  display: flex;
  align-content: center;

  width: 100%;
  height: fit-content;
  height: 70px;

  .wrapper {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;

    max-width: 1250px;
    margin: auto;
    padding: 0 30px;

    .mega-menu {
      display: flex;
      gap: 20px;

      .accordion-arrow {
        margin-left: 10px;
      }

      li {
        list-style: none;

        .mega-menu-title {
          display: flex;
          align-items: center;

          height: 70px;

          font-size: 20px;
          color: #221f1f;
          text-decoration: none;

          border-radius: 5px;

          &:hover {
            text-decoration: underline;
            text-underline-offset: 5px;
          }

          .link {
            margin-bottom: 15px;
            font-weight: 500;
          }
        }

        &:hover .dropdown {
          .accordion-arrow {
            transform: rotate(180deg);
            transition: all 0.2s ease;
          }
        }

        &:hover .sub-menu {
          visibility: visible;
        }
      }

      .sub-menu-title {
        .link {
          margin-bottom: 8px;
          font-size: 25px;
        }
      }
    }

    .nav-title {
      gap: 10px;
      font-size: 22px;
    }
  }
}

.sub-menu {
  position: absolute;
  top: 75px;

  display: flex;
  flex-direction: column;

  width: fit-content;
  margin: 0 10px;
  padding: 15px 30px;

  visibility: hidden;
  background-color: #fff;
  box-shadow: shadows.$page;

  transition: all 0.1s ease;

  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: start;

    width: 100%;
  }
}

.small-menu {
  flex-direction: column;

  .nav-title {
    .link {
      font-size: 18px;
    }
  }

  &.wrapper {
    gap: 5px;
    align-items: start;
    margin: 0;
    padding: 0;

    li {
      margin-left: 10px;
    }
  }

  .sub-menu-title {
    display: none;
  }

  .wrapper .mega-menu {
    gap: 0;

    .mega-menu-title {
      height: auto;
      font-size: 18px;
    }
  }

  .mega-menu {
    flex-direction: column;

    .accordion-arrow {
      &.opened {
        transform: rotate(180deg);
      }
    }

    .sub-menu {
      position: relative;
      top: 0;
      left: 0;

      overflow: hidden;
      flex-direction: column;

      max-height: 0;
      margin: 0;
      margin-left: 10px;
      padding: 5px;

      visibility: visible;
      box-shadow: none;

      transition: all 1s ease;

      .content {
        flex-direction: column;
        gap: 0;
      }

      &.opened {
        max-height: 1000px;
      }
    }
  }
}
</style>
    